//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, FormItem, Input, Button, Checkbox } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ctrlAccount } from "@/controller";
export default {
    name: "Reset",
    components: {
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
    },
    data() {
        return {
            newForm: {
                password: "",
                password1: "",
            },
            memEmail: null,
        };
    },
    watch: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
            routeParams() {
                return this.$route.params.nbr;
            },
        }),
    },
    watch: {},
    created() {
        this.getInit();
    },
    methods: {
        async getInit() {
            if (!this.routeParams) return false;
            this.memEmail = await ctrlAccount.getEmailByNbr({ nbr: this.routeParams });
        },
        //密碼二次檢驗
        isPassword2(rule, value, callback) {
            if (value === "") {
                callback(new Error("請再次輸入密碼"));
            } else if (value !== this.newForm.password) {
                callback(new Error("兩次輸入的密碼不一致"));
            } else {
                callback();
            }
        },
        //設定新密碼
        async passwdReset() {
            this.$refs.newForm.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "passwdReset",
                        val: { nbr: this.routeParams, newPasswd: this.newForm.password },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
    },
};
