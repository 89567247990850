var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main login-form reset"},[_c('div',{staticClass:"page-content"},[(_vm.Common.isExist(_vm.config))?_c('div',{staticClass:"inner reset-inner"},[_c('div',{staticClass:"title"},[_vm._v("重置密碼")]),_c('el-form',{ref:"newForm",attrs:{"model":_vm.newForm}},[[_c('el-form-item',{attrs:{"label":"電子信箱地址"}},[_c('div',[_vm._v(_vm._s(_vm.memEmail))])]),_c('el-form-item',{attrs:{"prop":"password","rules":[
                            {
                                validator: _vm.msg.eleIsPassword,
                                required: true,
                                trigger: 'blur',
                                message: _vm.msg.password,
                            } ]}},[_c('el-input',{attrs:{"placeholder":"設定密碼","show-password":""},model:{value:(_vm.newForm.password),callback:function ($$v) {_vm.$set(_vm.newForm, "password", $$v)},expression:"newForm.password"}})],1),_c('el-form-item',{attrs:{"prop":"password1","rules":[
                            {
                                validator: _vm.isPassword2,
                                trigger: 'blur',
                                required: true,
                            } ]}},[_c('el-input',{attrs:{"placeholder":"確認密碼","show-password":""},model:{value:(_vm.newForm.password1),callback:function ($$v) {_vm.$set(_vm.newForm, "password1", $$v)},expression:"newForm.password1"}})],1)],_c('div',{staticClass:"btn-grp"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.passwdReset}},[_vm._v(" 確認 ")])],1)],2)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }